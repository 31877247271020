
import { defineComponent, ref } from 'vue'
import ChatsSettingsPage from '@/components/pages/chats/settings/ChatsSettingsPage.vue'
import DetailsHero from '@/components/shared/templates/details/DetailsHero.vue'
import InfoCard from '@/components/shared/templates/InfoCard.vue'
import WhiteBlock from '@/components/shared/templates/WhiteBlock.vue'
import TmExpansion from '@/components/shared/TmExpansion.vue'
import SettingsAutoResponders from '@/components/pages/shared/settings/SettingsAutoResponders.vue'

export default defineComponent({
  components: {
    SettingsAutoResponders,
    WhiteBlock,
    TmExpansion,
    DetailsHero,
    InfoCard,
    ChatsSettingsPage,
  },
  setup() {
    const breadcrumbs = [
      { label: 'Messenger', url: { name: 'base.chats' } },
      { label: 'Settings', url: { name: 'base.chats.settings' } },
      { label: 'Auto-responders', url: { name: 'base.chats.settings.autoResponders' } },
      { label: 'Email auto-responders' },
    ]
    const outsideHours = ref(true)
    const withinHours = ref(true)

    return {
      withinHours,
      breadcrumbs,
      outsideHours,
    }
  },
})
