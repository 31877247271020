import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_details_hero = _resolveComponent("details-hero")!
  const _component_settings_auto_responders = _resolveComponent("settings-auto-responders")!
  const _component_tm_expansion = _resolveComponent("tm-expansion")!
  const _component_info_card = _resolveComponent("info-card")!
  const _component_white_block = _resolveComponent("white-block")!
  const _component_chats_settings_page = _resolveComponent("chats-settings-page")!

  return (_openBlock(), _createBlock(_component_chats_settings_page, { breadcrumbs: _ctx.breadcrumbs }, {
    default: _withCtx(() => [
      _createVNode(_component_details_hero, {
        title: "Email auto-responders",
        icon: "email",
        class: "mb-5"
      }),
      _createVNode(_component_white_block, null, {
        default: _withCtx(() => [
          _createVNode(_component_info_card, {
            class: "px-8",
            title: "Send auto-reply"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_tm_expansion, {
                "toggle-value": _ctx.outsideHours,
                "onUpdate:toggle-value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.outsideHours) = $event)),
                label: "Outside business hours",
                size: "medium",
                caption: "An auto-reply will be sent for every email received outside the business hours."
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_settings_auto_responders)
                ]),
                _: 1
              }, 8, ["toggle-value"]),
              _createVNode(_component_tm_expansion, {
                "toggle-value": _ctx.withinHours,
                "onUpdate:toggle-value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.withinHours) = $event)),
                label: "Within business hours",
                size: "medium",
                expanded: "",
                caption: "An auto-reply will be sent for every email received during the business hours."
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_settings_auto_responders)
                ]),
                _: 1
              }, 8, ["toggle-value"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["breadcrumbs"]))
}